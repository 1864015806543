import { Category, type CategoryType } from '~/types';

const STORAGE_KEY_CATEGORY = 'FILTER:CATEGORY'

export const useCategoryStore = defineStore('category', () => {
  const { loadValue, saveValue } = usePersistedSettings()

  const category = ref<CategoryType>()

  const isEnergy = computed(() => category.value === Category.ENERGY)
  const isTelco = computed(() => category.value === Category.TELCO)
  const isInsurance = computed(() => category.value === Category.INSURANCE)

  const categoryTitle = computed<string>(() => {
    if (isEnergy.value) {
      return 'Luce & Gas'
    }

    if (isTelco.value) {
      return 'Telefonia'
    }

    if (isInsurance.value) {
      return 'Assicurazioni'
    }

    return ''
  })

  const setCategory = (value?: CategoryType): boolean => {
    category.value = value
    saveValue(STORAGE_KEY_CATEGORY, value)
    return true
  }

  const loadRef = (ref: Ref, key: string) => {
    ref.value = loadValue<typeof ref.value>(key, ref.value).value
  }

  onMounted(() => {
    loadRef(category, STORAGE_KEY_CATEGORY)
  })

  return {
    category,
    categoryTitle,
    isEnergy,
    isTelco,
    isInsurance,
    setCategory
  }
})