import { asHTML, asLink, asText } from '@prismicio/client';

import type {
  EnergyRateType,
  EnergyRateVariantType,
  EnergySubCategoryType,
  PriceVariantType,
  GenericRateType,
  ClientType
} from '@/types'
import {
  EnergyRateVariant,
  Category,
  EnergySubCategory,
  PriceVariant,
  Client,
} from '@/types'

export const hasElectricity = (rate: GenericRateType) => 'electricity' in rate && !!rate.electricity
export const hasGas = (rate: GenericRateType) => 'gas' in rate && !!rate.gas
export const hasValidElectricity = (rate: GenericRateType) => !('electricity' in rate) || !!rate.electricity?.total
export const hasValidGas = (rate: GenericRateType) => !('gas' in rate) || !!rate.gas?.total

/**
 * Parses a single Prismic documents to a EnergyRateType object.
 * @param {Record<string, any>} prismicDocs - The list of documents fetched from Prismic.
 * @returns {EnergyRateType} - The parsed document.
 */
export const parseEnergyRate = (doc: Record<string, any>, category?: string): EnergyRateType => {
  const hasElectricityRate: boolean = doc.electricity && doc.electricity[0] && ((doc.electricity[0].price_f1 && doc.electricity[0].price_f2) || doc.electricity[0].price) && doc.electricity[0].static_rate
  const hasGasRate: boolean = doc.gas && doc.gas[0] && doc.gas[0].price && doc.gas[0].static_rate
  return {
    category,
    id: doc.id,
    slug: doc.slug,
    name: doc.nome,
    brandId: doc.brand.id || '',
    assetUrl: (doc.asset_url ? asLink(doc.asset_url) : '') || '',
    esclusive: doc.esclusive,
    description: asHTML(doc.description) || '',
    business: doc.business,
    bonus: doc.bonus || 0,
    promo: asHTML(doc.promo) || '',
    priceVariant: doc.price_variant || PriceVariant.FIXED,
    rateVariant: doc.rate_variant || EnergyRateVariant.MONO,
    payment: asText(doc.payment) || '',
    electricity: hasElectricityRate
      ? {
        priceF1: safeParseNumber(doc.electricity[0]?.price_f1),
        priceF2: safeParseNumber(doc.electricity[0]?.price_f2),
        price: safeParseNumber(doc.electricity[0]?.price),
        staticRate: safeParseNumber(doc.electricity[0]?.static_rate) || 0,
      }
      : undefined,
    gas: hasGasRate
      ? {
        price: safeParseNumber(doc.gas[0]?.price),
        staticRate: safeParseNumber(doc.gas[0]?.static_rate) || 0,
      }
      : undefined,
    technicalDocList: doc.technical_doc_list
      .map((td: any) => ({
        name: td.document_name,
        assetUrl: (td.document_asset_url ? asLink(td.document_asset_url) : '') || ''
      }))
      .filter((td: any) => !!td.name && !!td.assetUrl)
  }
}

/**
 * Parses a list of Prismic documents to a list of EnergyRateType objects.
 * @param {Record<string, any>[]} prismicDocs - The list of documents fetched from Prismic.
 * @returns {EnergyRateType[]} - The list of parsed documents.
 */
export const parseEnergyRateList = (value: Record<string, any>[], category?: string): EnergyRateType[] =>
  value.reduce((acc: EnergyRateType[], doc: Record<string, any>) => {
    acc.push(parseEnergyRate(doc, category))
    return acc
  }, [])

/**
 * Checks if the given rate has a valid price.
 * @param rate - The energy rate to be checked.
 * @returns {boolean} - Returns true if the rate has a valid price, false otherwise.
 */
export const hasValidPrice = (value: GenericRateType): boolean =>
  (!hasElectricity(value) || hasValidElectricity(value)) &&
  (!hasGas(value) || hasValidGas(value))


/**
* Filter a list of energy rate types.
* @param value - The energy rate list to be filtered.
* @param options - Object containing the filters to be applied.
* @returns {GenericRateType[]} - Filtered rate type list.
*/
export const filteredEnergyRateList = (value: EnergyRateType[], options: {
  clientType: ClientType,
  priceVariant: PriceVariantType,
  rateVariant: EnergyRateVariantType,
  subCategory: EnergySubCategoryType,
  supplierId: string,
}): EnergyRateType[] =>
  value
    .filter((data): data is EnergyRateType => {
      const { clientType, priceVariant, rateVariant, subCategory, supplierId } = options

      const matchCategory = subCategory === EnergySubCategory.ALL || (subCategory === EnergySubCategory.GAS && !!data.gas) || (subCategory === EnergySubCategory.ELECTRICITY && !!data.electricity)
      const matchClientType = clientType === Client.ALL || (clientType === Client.BUSINESS && !!data.business) || (clientType === Client.PRIVATE && !data.busines)
      const matchPriceVariant = priceVariant === PriceVariant.ALL || data.priceVariant?.toLowerCase() === priceVariant
      const matchRateVariant = rateVariant === EnergyRateVariant.ALL || data.rateVariant?.toLowerCase() === rateVariant
      const matchSupplier = supplierId === EnergySubCategory.ALL || data.brandId === supplierId

      return matchCategory &&
        matchClientType &&
        matchPriceVariant &&
        matchRateVariant &&
        matchSupplier
    })
    .sort((a: EnergyRateType, b: EnergyRateType) => (a.total || 0) - (b.total || 0)) as EnergyRateType[]