import { defineStore } from 'pinia'
import { filter, asLink } from '@prismicio/client';

import { usePrismicClient } from '~/composables/usePrismicClient'
import { ALL, type BrandType, type SelectOptionType } from '@/types'

export const useBrandStore = defineStore('brand', () => {
  const { client } = usePrismicClient()
  const { category } = storeToRefs(useCategoryStore())

  const brandList = ref<BrandType[]>([])

  const supplierList = computed<SelectOptionType[]>(() => {
    return [
      { id: '9999', label: 'Tutti', value: ALL },
      ...brandList.value
        .reduce<SelectOptionType[]>((acc, { categories, name }) => {
          if (category.value && categories?.includes(category.value)) {
            acc.push({
              id: name || '',
              label: name || '',
              value: name || '',
            })
          }
          return acc
        }, [])
    ]
  })

  const getBrandById = (id?: string) => {
    if (id === undefined) {
      return undefined
    }
    return brandList.value.find((brand: BrandType) => id === brand.id)
  }

  const getBrandByName = (name?: string) => {
    if (name === undefined) {
      return undefined
    }
    return brandList.value.find((brand: BrandType) => name === brand.name)
  }

  const parseBrand = (doc: Record<string, any>): BrandType => ({
    id: doc.id,
    name: doc.name,
    categories: doc.categories.map(({ category }: { category: string }) => category) || [],
    sort: doc.sort || 999,
    assetUrl: asLink(doc.asset_url) || '',
    externalUrl: asLink(doc.external_url) || '',
    esclusive: doc.esclusive,
  })

  /**
   * Parses a list of Prismic documents to a list of BrandType objects.
   * @param {Record<string, any>[]} prismicDocs - The list of documents fetched from Prismic.
   * @returns {BrandType[]} - The list of parsed documents.
   */
  const parseBrandList = (value: Record<string, any>[]): BrandType[] =>
    value.reduce((acc: BrandType[], doc: Record<string, any>) => {
      acc.push(parseBrand(doc))
      return acc
    }, [])
      .sort((a: BrandType, b: BrandType) => (a.sort || 0) - (b.sort || 0))

  const fetchBrandList = async (): Promise<BrandType[]> => {
    try {
      const type = 'brand'
      const response = await client.get({
        filters: [
          filter.at('document.type', type),
        ],
        pageSize: 1000
      })

      const parsedResponse =
        parseBrandList(response.results.map(({ data, id }) => ({ ...data, id })))

      return Promise.resolve(parsedResponse)
    } catch (error) {
      console.error('Error fetching brand data from Prismic:', error)
      return Promise.resolve([])
    }
  }

  const loadBrandList = async (): Promise<boolean> => {
    brandList.value = (await fetchBrandList()) || []
    return true
  }

  return {
    brandList,
    supplierList,

    getBrandById,
    getBrandByName,
    loadBrandList,
  }
})
