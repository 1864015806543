import { asHTML, asLink, asText } from '@prismicio/client';

import type { TelcoRateType, TelcoTechnologyType, TelcoSubCategoryType, ActivationCostType, ModemType, VoiceType, ClientType } from '@/types'
import { TelcoTechnology, TelcoSubCategory, Modem, ActivationCost, Voice, ALL, Client } from '@/types'

/**
 * Parses a single Prismic documents to a TelcoRateType object.
 * @param {Record<string, any>} prismicDocs - The list of documents fetched from Prismic.
 * @returns {TelcoRateType} - The parsed document.
 */
export const parseTelcoRate = (doc: Record<string, any>, category?: string): TelcoRateType =>
({
  category,
  id: doc.id,
  slug: doc.slug,
  name: doc.nome,
  brandId: doc.brand.id || '',
  assetUrl: (doc.asset_url ? asLink(doc.asset_url) : '') || '',
  business: doc.business,
  esclusive: doc.esclusive,
  voice: doc.voice,
  modem: doc.modem,
  activationCost: doc.activation_cost,
  telcoTechnology: doc.technology,
  description: asHTML(doc.description) || '',
  bonus: doc.bonus || 0,
  promo: asHTML(doc.promo) || '',
  subCategory: doc.insurance_type,
  payment: asText(doc.payment) || '',
  price: safeParseNumber(doc?.price),
  total: safeParseNumber(doc?.price),
  activationPrice: safeParseNumber(doc?.activation_price),
  modemPrice: safeParseNumber(doc?.modem_price),
  technicalDocList: doc.technical_doc_list
    .map((td: any) => ({
      name: td.document_name,
      assetUrl: asLink(td.document_asset_url) || ''
    }))
    .filter((td: any) => !!td.name && !!td.assetUrl)
})

/**
 * Parses a list of Prismic documents to a list of TelcoRateType objects.
 * @param {Record<string, any>[]} prismicDocs - The list of documents fetched from Prismic.
 * @returns {EnergyRTelcoRateTypeateType[]} - The list of parsed documents.
 */
export const parseTelcoRateList = (value: Record<string, any>[], category?: string): TelcoRateType[] =>
  value.reduce((acc: TelcoRateType[], doc: Record<string, any>) => {
    acc.push(parseTelcoRate(doc, category))
    return acc
  }, [])

/**
* Filter a list of telco rate types.
* @param value - The energy rate list to be filtered.
* @param options - Object containing the filters to be applied.
* @returns {TelcoRateType[]} - Filtered rate type list.
*/
export const filteredTelcoRateList = (value: TelcoRateType[], options: {
  clientType: ClientType,
  activationCost: ActivationCostType,
  modem: ModemType,
  supplierId: string,
  telcoTechnology: TelcoTechnologyType,
  voice: VoiceType,
}): TelcoRateType[] =>
  value
    .filter((data): data is TelcoRateType => {
      const { clientType, activationCost, modem, supplierId, telcoTechnology, voice } = options

      const matchClientType = clientType === Client.ALL || (clientType === Client.BUSINESS && !!data.business) || (clientType === Client.PRIVATE && !data.busines)
      const matchActivationCost = activationCost === ActivationCost.ALL || (data.activationCost && activationCost === ActivationCost.INCLUDED) || (!data.activationCost && activationCost === ActivationCost.NOT_INCLUDED)
      const matchModem = modem === Modem.ALL || (data.modem && modem === Modem.INCLUDED) || (!data.modem && modem === Modem.NOT_INCLUDED)
      const matchSupplier = supplierId === ALL || data.brandId === supplierId
      const matchTechnology = telcoTechnology === TelcoTechnology.ALL || data.telcoTechnology === telcoTechnology
      const matchVoice = voice === Voice.ALL || data.voice === voice

      return matchActivationCost &&
        matchClientType &&
        matchModem &&
        matchSupplier &&
        matchTechnology &&
        matchVoice
    })
    .sort((a: TelcoRateType, b: TelcoRateType) => (a.total || 0) - (b.total || 0)) as TelcoRateType[]