import type { PriceDetailType, EnergyRatePriceType, EnergyRateType, EnergyRateVariantType } from "~/types"
import { GasUsage, HouseHoldAppliance, EnergyRateVariant } from "~/types"
import { storeToRefs } from 'pinia'

// Adds more information no how the consume was obtained
// starting from the estimated KwH/SMC and the raw material costs
const DEBUG = false
const ACCISA_1 = 0.17
const ACCISA_2 = 0.17

export const usePriceEstimator = () => {
  const {
    consumeKnown,
    consumeInKwh,
    consumeInCubicMeters,
    consumePartition,
    estimatedConsumeInKwh,
    estimatedConsumeInCubicMeters,
    familyMembers,
  } = storeToRefs(useFilterStore())

  /**
   * Calculates and adds the percentage of each item's value relative to the total sum of all values.
   * Adds a 'percentage' property to each item in the array.
   *
   * @param {PriceDetailType[]} items - An array of objects, each containing a 'title' and 'value'.
   * @returns {PriceDetailType[]} A new array of items, each item includes a 'percentage' property.
   */
  const appendPercentages = (items: PriceDetailType[]): PriceDetailType[] => {
    const total = items
      .filter((({ title }) => title.toLowerCase() === 'totale'))
      .reduce((sum, item) => sum + (item?.value || 0), 0);
    return items.map(item => ({
      ...item,
      percentage: ((item?.value || 0) / total) * 100
    }));
  };

  /**
   * Stima il consumo in KWH
   */
  const getEstimatedKwHConsume = (householdApplianceList: string[]): number => {
    let result = 0

    // Calcolo il consumo base in base agli elettrodomestici
    if (householdApplianceList.includes(HouseHoldAppliance.AC)) {
      result += 425
    }
    if (householdApplianceList.includes(HouseHoldAppliance.DISH_WASHER)) {
      result += 777
    }
    if (householdApplianceList.includes(HouseHoldAppliance.FRIDGE)) {
      result += 305
    }
    if (householdApplianceList.includes(HouseHoldAppliance.OVEN)) {
      result += 54
    }
    if (householdApplianceList.includes(HouseHoldAppliance.WASHING_MACHINE)) {
      result += 240
    }
    if (householdApplianceList.includes(HouseHoldAppliance.WATER_HEATER)) {
      result += 1410
    }

    // Incremento del 25% per ogni membro della famiglia
    result *= Math.pow(1.25, Math.max(0, familyMembers.value - 1))

    return result
  }

  /**
   *  Stima il consumo in M cubi
   */
  const getEstimatedCubicMetersConsume = (gasUsageList: string[]): number => {
    let result = 0

    // Calcolo il consumo base in base all'utilizzo gas
    if (gasUsageList.includes(GasUsage.COOKING)) {
      result += 70
    }
    if (gasUsageList.includes(GasUsage.HEATING)) {
      result += 700
    }
    if (gasUsageList.includes(GasUsage.HOT_WATER)) {
      result += 130
    }

    // Incremento del 25% per ogni membro della famiglia
    result *= Math.pow(1.25, Math.max(0, familyMembers.value - 1))

    return result
  }

  /**
   * Stima il prezzo dell'offerta luce
   */
  const getElectricityPrice = (
    { price, priceF1, priceF2, staticRate }: EnergyRatePriceType,
    kwhEstimate: number,
    rateVariant: EnergyRateVariantType,
    bonus: number = 0,
    rate?: EnergyRateType
  ): { total?: number, priceDetail?: PriceDetailType[] } => {
    if (kwhEstimate === 0) {
      return { total: 0 }
    }

    const priceDetail: PriceDetailType[] = []
    let result = 0
    let consume = 0

    // Applico prezzo in base alla fascia oraria
    if (rateVariant?.toLowerCase() === EnergyRateVariant.MONO) {
      if (price === undefined) {
        console.error(`Rate electricity price not available for rate ${rate?.name}`, rate)
        priceDetail.push({
          title: 'Errore di calcolo: prezzo mancante',
        })
        return {}
      }

      if (DEBUG) {
        // Inizio dal prezzo KwH fascia monoraria
        priceDetail.push({
          title: 'Materia energia - monoraria',
          value: price
        })
      }

      // Calcolo il consumo
      consume = price * kwhEstimate
      priceDetail.push({
        title: `Materia prima ${kwhEstimate} KwH`,
        value: consume
      })

    } else {
      if (priceF1 === undefined || priceF2 === undefined) {
        console.error(`Rate electricity priceF1/priceF2 not available for rate ${rate?.name}`, rate)
        priceDetail.push({
          title: 'Errore di calcolo: prezzo F1 o F2 mancante',
        })
        return {}
      }

      const dayConsumption = consumePartition.value / 100
      const nightConsumption = (100 - consumePartition.value) / 100

      const fasciaConsumoF1 = dayConsumption * priceF1
      const fasciaConsumoF2 = nightConsumption * priceF2

      if (DEBUG) {
        // Inizio dal prezzo KwH fascia bioraria
        priceDetail.push({
          title: `Materia energia - F1 ${dayConsumption}%`,
          value: fasciaConsumoF1
        })
        priceDetail.push({
          title: `Materia energia - F2 ${nightConsumption}%`,
          value: fasciaConsumoF2
        })
      }

      // Applico il consumo
      consume = kwhEstimate * (fasciaConsumoF1 + fasciaConsumoF2)
      priceDetail.push({
        title: `Materia prima ${kwhEstimate} KwH`,
        value: consume
      })
    }

    if (staticRate === undefined) {
      console.error(`Rate electricity staticRate not available for rate ${rate?.name}`, rate)
      priceDetail.push({
        title: 'Errore di calcolo: corrispettivo di commercializzazione vendita mancante',
      })
      return {}
    }

    // Imposta sulla materia prima - Accisa
    const tax1 = Math.max((kwhEstimate - 1250) * 0.0227, 0)
    priceDetail.push({
      title: 'Accisa',
      value: tax1
    })

    // Imposta sulla materia prima - Spese di Trasporto e Gestione
    const tax2 = (consume * 0.17)
    priceDetail.push({
      title: 'Spese di Trasporto e Gestione',
      value: tax2
    })

    // Imposta sulla materia prima - Oneri di Sistema
    const tax3 = (consume * 0.125)
    priceDetail.push({
      title: 'Oneri di Sistema',
      value: tax3
    })

    // Applico tassazione - IVA 10%
    const taxIVA = ((consume + (staticRate * 12)) * 0.1)
    priceDetail.push({
      title: 'IVA',
      value: taxIVA
    })

    if (bonus !== 0) {
      // Applico bonus
      priceDetail.push({
        title: 'Sconti',
        value: -bonus,
        bonus: true
      })
    }

    // Addiziono tutte le imposte sulla materia prima + IVA
    result = consume + (staticRate * 12) + tax1 + tax2 + tax3 + taxIVA - bonus

    // Spesa annuale
    priceDetail.push({
      title: 'Totale',
      value: result
    })

    // Ottengo prezzo mensile
    result /= 12

    return { total: result, priceDetail: appendPercentages(priceDetail) }
  }

  /**
   * Stima il prezzo dell'offerta gas
   */
  const getGasPrice = (
    { price, staticRate }: EnergyRatePriceType,
    smcEstimate: number,
    bonus: number = 0,
    rate?: EnergyRateType,
  ): { total?: number, priceDetail?: PriceDetailType[] } => {
    if (smcEstimate === 0) {
      return { total: 0 }
    }

    const smcStandard = 480

    const priceDetail: PriceDetailType[] = []

    if (price == undefined) {
      console.error(`Rate gas price not available for rate ${rate?.name}`, rate)
      priceDetail.push({
        title: 'Errore di calcolo: prezzo mancante',
      })
      return {}
    }

    // Inizio dal prezzo Smc
    let result = 0
    let consume = 0

    if (DEBUG) {
      // Inizio dal prezzo Smc fascia monoraria
      priceDetail.push({
        title: 'Materia gas',
        value: price
      })
    }

    // Applico il consumo inserito dall'utente o stimato
    priceDetail.push({
      title: `Materia prima ${smcEstimate} SmC`,
      value: price * smcEstimate
    })
    consume = price * smcEstimate

    // Tassazione - Accisa + Add. Regionale
    // TODO differenziare moltiplicazione primi 480 e oltre 
    // definire il costo accisa1 e accisa2
    const tax1 = smcEstimate * ACCISA_1
    priceDetail.push({
      title: `Accisa + Add Regionale`,
      value: tax1
    })

    // Tassazione - Spese di Trasporto e Gestione
    const tax2 = 59 + (0.22 * smcEstimate)
    priceDetail.push({
      title: `Spese di Trasporto e Gestione`,
      value: tax2
    })

    // Tassazione - Oneri di Sistema
    const tax3 = 0.037 * smcEstimate
    priceDetail.push({
      title: `Oneri di Sistema`,
      value: tax3
    })


    // IVA sui primi 480 SmC)
    // TODO differenziare moltiplicazione primi 480 e oltre 
    const taxIVA1 = (price * smcStandard * 0.10)
    priceDetail.push({
      title: `IVA sui primi 480 SmC`,
      value: taxIVA1
    })

    // IVA oltre i 480 SmC
    const taxIVA2 = (price * (smcEstimate - smcStandard) * 0.22)
    priceDetail.push({
      title: `IVA oltre i 480 SmC`,
      value: taxIVA2
    })

    // IVA corrispettivo di commercializzazione vendita
    // const taxIVA3 = (staticRate * 12 * 1.22) - (staticRate * 12)
    const taxIVA3 = staticRate * 12 * 0.22
    priceDetail.push({
      title: `IVA corrispettivo di commercializzazione vendita`,
      value: taxIVA3
    })

    if (bonus !== 0) {
      // Applico bonus
      priceDetail.push({
        title: 'Sconti',
        value: -bonus,
        bonus: true
      })
    }

    // Addiziono tutte le tasse
    result = consume + (staticRate * 12) + tax1 + tax2 + tax3 + taxIVA1 + taxIVA2 + taxIVA3 - bonus

    // Spesa annuale
    priceDetail.push({
      title: 'Totale',
      value: result
    })

    // Ottengo prezzo mensile
    result /= 12

    return { total: result, priceDetail: appendPercentages(priceDetail) }
  }

  /**
   * Stima il prezzo finale dell'offerta luce E/O gas
   */
  const rateWithPriceInfo = (
    rate: EnergyRateType,
  ): EnergyRateType => {
    const {
      bonus,
      gas,
      electricity,
      rateVariant
    } = rate

    const result: EnergyRateType = rate

    if (electricity !== undefined) {
      const consume = consumeKnown.value
        ? consumeInKwh.value
        : estimatedConsumeInKwh.value

      if (consume !== undefined) {
        const { total, priceDetail } =
          getElectricityPrice(electricity, consume, rateVariant, gas ? ((bonus || 0) / 2) : bonus, rate)
        result.electricity = { ...electricity, priceDetail }
        result.electricity.total = total || 0
      }
    }

    if (gas !== undefined) {
      const consume = consumeKnown.value
        ? consumeInCubicMeters.value
        : estimatedConsumeInCubicMeters.value

      if (consume !== undefined) {
        const { total, priceDetail } =
          getGasPrice(gas, consume, electricity ? ((bonus || 0) / 2) : bonus, rate)
        result.gas = { ...gas, priceDetail }
        result.gas.total = total || 0
      }
    }

    result.total = (result.electricity?.total || 0) + (result.gas?.total || 0)

    return result
  }

  return {
    getEstimatedKwHConsume,
    getEstimatedCubicMetersConsume,
    rateWithPriceInfo
  }
}

