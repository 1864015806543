import { defineStore } from 'pinia'
import type {
  PriceVariantType,
  EnergySubCategoryType,
  SelectOptionType,
  EnergyRateVariantType,
  TelcoTechnologyType,
  InsuranceSubCategoryType,
  TelcoSubCategoryType,
  ModemType,
  VoiceType,
  ActivationCostType,
  ClientType,
} from '@/types'

import {
  GasUsage,
  HouseHoldAppliance,
  PriceVariant,
  EnergySubCategory,
  TelcoTechnology,
  InsuranceSubCategory,
  TelcoSubCategory,
  ActivationCost,
  Modem,
  Voice,
  Client,
  ALL,
  EnergyRateVariant,
} from '@/types'

const STORAGE_KEY_CITY = 'FILTER:CITY'
const STORAGE_KEY_CLIENT_TYPE = 'FILTER:CLIENT_TYPE'
const STORAGE_KEY_PROVINCE = 'FILTER:PROVINCE'
const STORAGE_KEY_SUPPLIER = 'FILTER:SUPPLIER'
const STORAGE_KEY_ACTIVATION_COST = 'FILTER:ACTIVATION_COST'
const STORAGE_KEY_MODEM = 'FILTER:MODEM'
const STORAGE_KEY_VOICE = 'FILTER:VOICE'
const STORAGE_KEY_ENERGY_SUB_CATEGORY = 'FILTER:ENERGY_SUB_CATEGORY'
const STORAGE_KEY_INSURANCE_SUB_CATEGORY = 'FILTER:INSURANCE_SUB_CATEGORY'
const STORAGE_KEY_TELCO_SUB_CATEGORY = 'FILTER:TELCO_SUB_CATEGORY'
const STORAGE_KEY_TELCO_TECHNOLOGY = 'FILTER:TECHNOLOGY'
const STORAGE_KEY_PRICE_VARIANT = 'FILTER:PRICE_VARIANT'
const STORAGE_KEY_RATE_VARIANT = 'FILTER:RATE_VARIANT'
const STORAGE_KEY_CONSUME_KNOWN = 'FILTER:CONSUME_KNOWN'
const STORAGE_KEY_HOUSE_HOLD_APPLIANCE_LIST = 'FILTER:HOUSE_HOLD_APPLIANCE_LIST'
const STORAGE_KEY_FAMILY_MEMBER = 'FILTER:FAMILY_MEMBERS'
const STORAGE_KEY_GAS_USAGE_LIST = 'FILTER:GAS_USAGE_LIST'
const STORAGE_KEY_CONSUME_PARTITION = 'FILTER:CONSUME_PARTITION'
const STORAGE_KEY_CONSUME_IN_KWH = 'FILTER:CONSUME_IN_KWH'
const STORAGE_KEY_CONSUME_IN_CUBIC_METERS = 'FILTER:CONSUME_IN_CUBIC_METERS'
const STORAGE_KEY_ESTIMATE_CONSUME_IN_KWH = 'FILTER:ESTIMATE_CONSUME_IN_KWH'
const STORAGE_KEY_ESTIMATE_CONSUME_IN_CUBIC_METERS = 'FILTER:ESTIMATE_CONSUME_IN_CUBIC_METERS'

export const useFilterStore = defineStore('filter', () => {
  const { loadRef, saveValue } = usePersistedSettings()

  const clientType = ref<ClientType>(Client.PRIVATE)
  const city = ref(ALL)
  const province = ref(ALL)
  const supplier = ref(ALL)
  const activationCost = ref<ActivationCostType>(ActivationCost.ALL)
  const modem = ref<ModemType>(Modem.ALL)
  const voice = ref<VoiceType>(Voice.ALL)
  const energySubCategory = ref<EnergySubCategoryType>(EnergySubCategory.ALL)
  const insuranceSubCategory = ref<InsuranceSubCategoryType>(InsuranceSubCategory.ALL)
  const telcoSubCategory = ref<TelcoSubCategoryType>(TelcoSubCategory.ALL)
  const telcoTechnology = ref<TelcoTechnologyType>(TelcoTechnology.ALL)
  const priceVariant = ref<PriceVariantType>(PriceVariant.ALL)
  const rateVariant = ref<EnergyRateVariantType>(PriceVariant.ALL)
  const consumeKnown = ref(false)
  const householdApplianceList = ref<SelectOptionType[]>([
    { id: 1, label: HouseHoldAppliance.AC, icon: HouseHoldAppliance.AC, value: false },
    { id: 2, label: HouseHoldAppliance.DISH_WASHER, icon: HouseHoldAppliance.DISH_WASHER, value: false },
    { id: 3, label: HouseHoldAppliance.FRIDGE, icon: HouseHoldAppliance.FRIDGE, value: true },
    { id: 4, label: HouseHoldAppliance.OVEN, icon: HouseHoldAppliance.OVEN, value: true },
    { id: 5, label: HouseHoldAppliance.WASHING_MACHINE, icon: HouseHoldAppliance.WASHING_MACHINE, value: true },
    { id: 6, label: HouseHoldAppliance.WATER_HEATER, icon: HouseHoldAppliance.WATER_HEATER, value: false },
  ])
  const familyMembers = ref(2)
  const gasUsageList = ref<SelectOptionType[]>([
    { id: 1, label: GasUsage.COOKING, value: true },
    { id: 2, label: GasUsage.HEATING, value: false },
    { id: 3, label: GasUsage.HOT_WATER, value: true },
  ])
  const consumePartition = ref(50)
  const consumeInKwh = ref<number | undefined>()
  const consumeInCubicMeters = ref<number | undefined>(undefined)
  const estimatedConsumeInKwh = ref<number | undefined>(100)
  const estimatedConsumeInCubicMeters = ref<number | undefined>(350)

  const setCity = (value: string) => {
    city.value = value
    saveValue(STORAGE_KEY_CITY, value)
  }

  const setProvince = (value: string) => {
    province.value = value
    saveValue(STORAGE_KEY_PROVINCE, value)
  }

  const setClientType = (value: ClientType) => {
    clientType.value = value
    saveValue(STORAGE_KEY_CLIENT_TYPE, value)
  }

  const setSupplier = (value: string) => {
    supplier.value = value
    saveValue(STORAGE_KEY_SUPPLIER, value)
  }

  const setActivationCost = (value: ActivationCostType) => {
    activationCost.value = value
    saveValue(STORAGE_KEY_ACTIVATION_COST, value)
  }

  const setModem = (value: ModemType) => {
    modem.value = value
    saveValue(STORAGE_KEY_MODEM, value)
  }

  const setVoice = (value: VoiceType) => {
    voice.value = value
    saveValue(STORAGE_KEY_VOICE, value)
  }

  const setEnergySubCategory = (value: EnergySubCategoryType) => {
    energySubCategory.value = value
    saveValue(STORAGE_KEY_ENERGY_SUB_CATEGORY, value)
  }

  const setInsuranceSubCategory = (value: InsuranceSubCategoryType) => {
    insuranceSubCategory.value = value
    saveValue(STORAGE_KEY_INSURANCE_SUB_CATEGORY, value)
  }

  const setTelcoSubCategory = (value: TelcoSubCategoryType) => {
    telcoSubCategory.value = value
    saveValue(STORAGE_KEY_TELCO_SUB_CATEGORY, value)
  }

  const setTelcoTechnology = (value: TelcoTechnologyType) => {
    telcoTechnology.value = value
    saveValue(STORAGE_KEY_TELCO_TECHNOLOGY, value)
  }

  const setPriceVariant = (value: PriceVariantType) => {
    priceVariant.value = value
    saveValue(STORAGE_KEY_PRICE_VARIANT, value)
  }

  const setRateVariant = (value: EnergyRateVariantType) => {
    rateVariant.value = value
    saveValue(STORAGE_KEY_RATE_VARIANT, value)
  }

  const setConsumeKnown = (value: boolean) => {
    consumeKnown.value = value
    saveValue(STORAGE_KEY_CONSUME_KNOWN, value)
  }

  const setHouseHoldApplianceList = (value: string | null) => {
    if (value === null) {
      householdApplianceList.value = [
        { id: 1, label: HouseHoldAppliance.AC, icon: HouseHoldAppliance.AC, value: false },
        { id: 2, label: HouseHoldAppliance.DISH_WASHER, icon: HouseHoldAppliance.DISH_WASHER, value: false },
        { id: 3, label: HouseHoldAppliance.FRIDGE, icon: HouseHoldAppliance.FRIDGE, value: true },
        { id: 4, label: HouseHoldAppliance.OVEN, icon: HouseHoldAppliance.OVEN, value: true },
        { id: 5, label: HouseHoldAppliance.WASHING_MACHINE, icon: HouseHoldAppliance.WASHING_MACHINE, value: true },
        { id: 6, label: HouseHoldAppliance.WATER_HEATER, icon: HouseHoldAppliance.WATER_HEATER, value: false },
      ]
      return
    }

    const target = householdApplianceList.value.find(({ label }) => label === value)

    if (!target) {
      return
    }

    target.value = !target.value
    saveValue(STORAGE_KEY_HOUSE_HOLD_APPLIANCE_LIST, householdApplianceList.value)
  }

  const setFamilyMembers = (value: number) => {
    familyMembers.value = value
    saveValue(STORAGE_KEY_FAMILY_MEMBER, value)
  }

  const setGasUsages = (value: string | null) => {
    if (value === null) {
      gasUsageList.value = [
        { id: 1, label: GasUsage.COOKING, value: true },
        { id: 2, label: GasUsage.HEATING, value: false },
        { id: 3, label: GasUsage.HOT_WATER, value: true },
      ]
      return
    }

    const target = gasUsageList.value.find(({ label }) => label === value)

    if (target === undefined) {
      return
    }

    target.value = !target?.value
    saveValue(STORAGE_KEY_GAS_USAGE_LIST, gasUsageList.value)
  }

  const setConsumePartition = (value: number) => {
    consumePartition.value = value
    saveValue(STORAGE_KEY_CONSUME_PARTITION, value)
  }

  const setEstimatedConsumeInKwh = (value: number | undefined) => {
    estimatedConsumeInKwh.value = value
    saveValue(STORAGE_KEY_ESTIMATE_CONSUME_IN_KWH, value)
  }

  const setEstimatedConsumeInCubicMeters = (value: number | undefined) => {
    estimatedConsumeInCubicMeters.value = value
    saveValue(STORAGE_KEY_ESTIMATE_CONSUME_IN_CUBIC_METERS, value)
  }

  const setConsumeInCubicMeters = (value: number | undefined) => {
    consumeInCubicMeters.value = value
    saveValue(STORAGE_KEY_CONSUME_IN_CUBIC_METERS, value)
  }

  const setConsumeInKwh = (value: number | undefined) => {
    consumeInKwh.value = value
    saveValue(STORAGE_KEY_CONSUME_IN_KWH, value)
  }

  const resetFilters = () => {
    setCity(ALL)
    setProvince(ALL)
    setClientType(Client.ALL)
    setSupplier(ALL)
    setActivationCost(ActivationCost.ALL)
    setModem(Modem.ALL)
    setVoice(Voice.ALL)
    setEnergySubCategory(EnergySubCategory.ALL)
    setInsuranceSubCategory(InsuranceSubCategory.ALL)
    setTelcoSubCategory(TelcoSubCategory.ALL)
    setTelcoTechnology(TelcoTechnology.ALL)
    setPriceVariant(PriceVariant.ALL)
    setRateVariant(EnergyRateVariant.ALL)
    setConsumeKnown(false)
    setFamilyMembers(2)
    setHouseHoldApplianceList(null)
    setGasUsages(null)
    setConsumePartition(50)
    setEstimatedConsumeInKwh(0)
    setEstimatedConsumeInCubicMeters(0)
    setConsumeInKwh(1000)
    setConsumeInCubicMeters(250)
  }

  onMounted(() => {
    loadRef(energySubCategory, STORAGE_KEY_ENERGY_SUB_CATEGORY)
    loadRef(city, STORAGE_KEY_CITY)
    loadRef(clientType, STORAGE_KEY_CLIENT_TYPE)
    loadRef(province, STORAGE_KEY_PROVINCE)
    loadRef(supplier, STORAGE_KEY_SUPPLIER)
    loadRef(priceVariant, STORAGE_KEY_PRICE_VARIANT)
    loadRef(rateVariant, STORAGE_KEY_RATE_VARIANT)
    loadRef(consumeKnown, STORAGE_KEY_CONSUME_KNOWN)
    loadRef(householdApplianceList, STORAGE_KEY_HOUSE_HOLD_APPLIANCE_LIST)
    loadRef(familyMembers, STORAGE_KEY_FAMILY_MEMBER)
    loadRef(gasUsageList, STORAGE_KEY_GAS_USAGE_LIST)
    loadRef(consumePartition, STORAGE_KEY_CONSUME_PARTITION)
    loadRef(estimatedConsumeInKwh, STORAGE_KEY_ESTIMATE_CONSUME_IN_KWH)
    loadRef(estimatedConsumeInCubicMeters, STORAGE_KEY_ESTIMATE_CONSUME_IN_CUBIC_METERS)
    loadRef(consumeInKwh, STORAGE_KEY_CONSUME_IN_KWH)
    loadRef(consumeInKwh, STORAGE_KEY_CONSUME_IN_KWH)
    loadRef(consumeInCubicMeters, STORAGE_KEY_CONSUME_IN_CUBIC_METERS)
  })

  return {
    city,
    clientType,
    province,
    supplier,
    activationCost,
    modem,
    voice,
    telcoTechnology,
    energySubCategory,
    insuranceSubCategory,
    telcoSubCategory,
    priceVariant,
    rateVariant,
    consumeKnown,
    householdApplianceList,
    familyMembers,
    gasUsageList,
    consumePartition,
    estimatedConsumeInKwh,
    estimatedConsumeInCubicMeters,
    consumeInKwh,
    consumeInCubicMeters,

    setCity,
    setClientType,
    setProvince,
    setSupplier,
    setActivationCost,
    setModem,
    setVoice,
    setEnergySubCategory,
    setInsuranceSubCategory,
    setTelcoTechnology,
    setTelcoSubCategory,
    setPriceVariant,
    setRateVariant,
    setConsumeKnown,
    setHouseHoldApplianceList,
    setFamilyMembers,
    setGasUsages,
    setConsumePartition,
    setEstimatedConsumeInKwh,
    setEstimatedConsumeInCubicMeters,
    setConsumeInKwh,
    setConsumeInCubicMeters,

    resetFilters,
  }
})
