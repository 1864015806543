import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import toNumber from 'lodash/toNumber'

/**
* Safely converts a string to a number, handling European-style decimal separators.
* @param value The string to be converted.
* @returns The number representation of the string, or null if the conversion is not possible.
*/
export const safeParseNumber = (value: string | undefined | null): number | undefined => {
  if (process.server) {
    return
  }

  if (isNil(value)) {
    return;
  }

  const normalizedValue = value.trim().replace(',', '.');

  if (isEmpty(normalizedValue) || isNaN(Number(normalizedValue))) {
    return;
  }

  return toNumber(normalizedValue);
};