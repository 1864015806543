import { asHTML, asLink } from '@prismicio/client';

import { Client, InsuranceSubCategory, type ClientType, type GenericRateType, type InsuranceRateType, type InsuranceSubCategoryType } from '@/types'

/**
 * Parses a single Prismic documents to a InsuranceRateType object.
 * @param {Record<string, any>} prismicDocs - The list of documents fetched from Prismic.
 * @returns {InsuranceRateType} - The parsed document.
 */
export const parseInsuranceRate = (doc: Record<string, any>, category?: string): InsuranceRateType =>
({
  category,
  id: doc.id,
  slug: doc.slug,
  name: doc.nome,
  brandId: doc.brand.id || '',
  assetUrl: (doc.asset_url ? asLink(doc.asset_url) : '') || '',
  business: doc.business,
  esclusive: doc.esclusive,
  description: asHTML(doc.description) || '',
  bonus: doc.bonus || 0,
  promo: asHTML(doc.promo) || '',
  price: safeParseNumber(doc?.price),
  total: safeParseNumber(doc?.price),
  subCategory: doc.insurance_type,
  technicalDocList: doc.technical_doc_list
    .map((td: any) => ({
      name: td.document_name,
      assetUrl: (td.document_asset_url ? asLink(td.document_asset_url) : '') || ''
    }))
    .filter((td: any) => !!td.name && !!td.assetUrl)
})

/**
 * Parses a list of Prismic documents to a list of InsuranceRateType objects.
 * @param {Record<string, any>[]} prismicDocs - The list of documents fetched from Prismic.
 * @returns {InsuranceRateType[]} - The list of parsed documents.
 */
export const parseInsuranceRateList = (value: Record<string, any>[], category?: string): InsuranceRateType[] =>
  value.reduce((acc: InsuranceRateType[], doc: Record<string, any>) => {
    acc.push(parseInsuranceRate(doc, category))
    return acc
  }, [])


/**
* Filter a list of insurance rate types.
* @param value - The energy rate list to be filtered.
* @param options - Object containing the filters to be applied.
* @returns {InsuranceRateType[]} - Filtered rate type list.
*/
export const filteredInsuranceRateList = (value: InsuranceRateType[], options: {
  clientType: ClientType,
  subCategory: InsuranceSubCategoryType,
  supplierId: string,
}): InsuranceRateType[] =>
  value
    .filter((data): data is InsuranceRateType => {
      const { clientType, subCategory, supplierId } = options

      const matchClientType = clientType === Client.ALL || (clientType === Client.BUSINESS && !!data.business) || (clientType === Client.PRIVATE && !data.busines)
      const matchSubCategory = subCategory === InsuranceSubCategory.ALL || data.subCategory === subCategory
      const matchSupplier = supplierId === InsuranceSubCategory.ALL || data.brandId === supplierId

      return matchClientType &&
        matchSubCategory &&
        matchSupplier
    }).sort((a: InsuranceRateType, b: InsuranceRateType) => (a.total || 0) - (b.total || 0)) as InsuranceRateType[]